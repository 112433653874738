export const UserIcon = (props: any) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...props}
    >
      <g id="User">
        <g id="Icon">
          <path
            d="M10.5 9C12.1569 9 13.5 7.65685 13.5 6C13.5 4.34315 12.1569 3 10.5 3C8.84315 3 7.5 4.34315 7.5 6C7.5 7.65685 8.84315 9 10.5 9Z"
            fill="currentColor"
          />
          <path
            d="M3.5 18C3.5 14.134 6.63401 11 10.5 11C14.366 11 17.5 14.134 17.5 18H3.5Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  )
}
