export function ThumbsdownActionIcon(props: any) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...props}
    >
      <g id="Thumb up">
        <path
          id="Icon"
          d="M14 14H18.7639C20.2507 14 21.2177 12.4354 20.5528 11.1056L17.0528 4.10557C16.714 3.42801 16.0215 3 15.2639 3H11.2462C11.0827 3 10.9198 3.02005 10.7611 3.05972L7 4M14 14V19C14 20.1046 13.1046 21 12 21H11.9045C11.405 21 11 20.595 11 20.0955C11 19.3812 10.7886 18.6828 10.3923 18.0885L7 13V4M14 14H12M7 4H5C3.89543 4 3 4.89543 3 6V12C3 13.1046 3.89543 14 5 14H7.5"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
