export function Tapioca(props: any) {
  return (
    <svg
      width="70"
      height="20"
      viewBox="0 0 70 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M4.56014 15.9628C5.21812 15.9628 5.70218 15.868 6.06854 15.7485C6.1482 15.7226 6.20019 15.6472 6.20019 15.5634V14.0814C6.20019 13.9391 6.05507 13.8416 5.91777 13.8793C5.65654 13.9509 5.37027 13.9828 5.02015 13.9828C4.26013 13.9828 3.82012 13.5628 3.82012 12.6427V7.34258C3.82012 7.23212 3.90966 7.14258 4.02012 7.14258H5.92019C6.03064 7.14258 6.12019 7.05304 6.12019 6.94258V5.52252C6.12019 5.41207 6.03064 5.32253 5.92019 5.32253H4.02012C3.90966 5.32253 3.82012 5.23298 3.82012 5.12253V3.22245C3.82012 3.112 3.73057 3.02245 3.62012 3.02245H1.60004C1.48959 3.02245 1.40004 3.112 1.40004 3.22246V5.12253C1.40004 5.23298 1.3105 5.32253 1.20004 5.32253H0.2C0.089543 5.32253 0 5.41207 0 5.52253V6.94258C0 7.05304 0.0895431 7.14258 0.2 7.14258H1.20004C1.3105 7.14258 1.40004 7.23212 1.40004 7.34258V12.8628C1.40004 14.8428 2.48008 15.9628 4.56014 15.9628Z"
        fill="#050719"
      />
      <path
        d="M11.2208 15.9828C12.5348 15.9828 13.3902 15.5917 14.0307 14.9533C14.1666 14.8179 14.4209 14.9074 14.4209 15.0993V15.5828C14.4209 15.6933 14.5104 15.7828 14.6209 15.7828H16.601C16.7114 15.7828 16.801 15.6933 16.801 15.5828V8.92263C16.801 6.06255 14.9609 5.10252 12.6209 5.10252C10.3511 5.10252 8.45761 6.0622 8.16341 8.34661C8.14848 8.46255 8.24088 8.56262 8.35778 8.56262H10.3025C10.4041 8.56262 10.4887 8.48613 10.5067 8.38616C10.6748 7.45502 11.2939 6.92257 12.4808 6.92257C13.9009 6.92257 14.3809 7.62259 14.3809 9.02264V9.36265C14.3809 9.47311 14.2914 9.56265 14.1809 9.56265H13.0809C10.0808 9.56265 7.7207 10.4427 7.7207 12.8828C7.7207 15.0628 9.30075 15.9828 11.2208 15.9828ZM11.8008 14.2428C10.6008 14.2428 10.1208 13.6628 10.1208 12.7828C10.1208 11.5027 11.3008 11.0827 13.1409 11.0827H14.1809C14.2914 11.0827 14.3809 11.1722 14.3809 11.2827V12.1227C14.3809 13.4628 13.2809 14.2428 11.8008 14.2428Z"
        fill="#050719"
      />
      <path
        d="M19.5748 19.163C19.5748 19.2734 19.6644 19.363 19.7748 19.363H21.7949C21.9053 19.363 21.9949 19.2734 21.9949 19.163V14.9227C21.9949 14.7157 22.3097 14.6217 22.4497 14.774C23.0991 15.4809 24.1163 15.9828 25.415 15.9828C28.1151 15.9828 30.0551 13.9428 30.0551 10.5827V10.4227C30.0551 7.04258 28.0351 5.10252 25.415 5.10252C24.1904 5.10252 23.1176 5.67158 22.4378 6.4075C22.2991 6.55765 21.9949 6.46353 21.9949 6.25912V5.52253C21.9949 5.41207 21.9053 5.32253 21.7949 5.32253H19.7748C19.6644 5.32253 19.5748 5.41207 19.5748 5.52253V19.163ZM24.815 14.0628C23.0549 14.0628 21.9149 12.9628 21.9149 10.6227V10.4627C21.9149 8.14261 23.1149 7.00258 24.795 7.00258C26.375 7.00258 27.5751 8.14261 27.5751 10.4627V10.6227C27.5751 12.8628 26.595 14.0628 24.815 14.0628Z"
        fill="#050719"
      />
      <path
        d="M32.2915 15.5828C32.2915 15.6933 32.381 15.7828 32.4915 15.7828H34.5115C34.622 15.7828 34.7115 15.6933 34.7115 15.5828V5.52252C34.7115 5.41207 34.622 5.32253 34.5115 5.32253H32.4915C32.381 5.32253 32.2915 5.41207 32.2915 5.52253V15.5828ZM33.4715 3.60247C34.2715 3.60247 34.9115 3.00245 34.9115 2.22243C34.9115 1.42241 34.2715 0.822388 33.4715 0.822388C32.6715 0.822388 32.0314 1.42241 32.0314 2.22243C32.0314 3.00245 32.6715 3.60247 33.4715 3.60247Z"
        fill="#050719"
      />
      <path
        d="M42.3414 14.1028C40.5014 14.1028 39.4414 12.7628 39.4414 10.6227V10.4627C39.4414 8.30261 40.5414 7.00258 42.3414 7.00258C44.1415 7.00258 45.2215 8.32262 45.2215 10.4827V10.6227C45.2215 12.7628 44.1415 14.1028 42.3414 14.1028ZM42.3214 15.9828C45.4015 15.9828 47.7016 13.8628 47.7016 10.6027V10.4427C47.7016 7.24258 45.4015 5.10252 42.3414 5.10252C39.2613 5.10252 36.9613 7.26258 36.9613 10.5027V10.6627C36.9613 13.8428 39.2413 15.9828 42.3214 15.9828Z"
        fill="#050719"
      />
      <path
        d="M54.4684 15.9828C57.223 15.9828 59.0181 14.3835 59.2878 12.0974C59.3015 11.9818 59.2093 11.8827 59.093 11.8827H57.2704C57.1673 11.8827 57.0817 11.9617 57.066 12.0637C56.8566 13.4247 55.7952 14.1228 54.5684 14.1228C52.7883 14.1228 51.6683 12.9228 51.6683 10.6427V10.4827C51.6683 8.32262 52.8483 7.04258 54.5084 7.04258C55.7348 7.04258 56.6308 7.57507 56.8796 8.85117C56.8986 8.94886 56.9824 9.02264 57.0819 9.02264H59.0281C59.1462 9.02264 59.2389 8.92009 59.223 8.80305C58.8558 6.0895 56.7285 5.10252 54.4684 5.10252C51.5483 5.10252 49.1882 7.20258 49.1882 10.5227V10.6827C49.1882 14.0228 51.4683 15.9828 54.4684 15.9828Z"
        fill="#050719"
      />
      <path
        d="M64.1326 15.9828C65.4466 15.9828 66.302 15.5917 66.9424 14.9533C67.0783 14.8179 67.3327 14.9074 67.3327 15.0993V15.5828C67.3327 15.6933 67.4222 15.7828 67.5327 15.7828H69.5127C69.6232 15.7828 69.7127 15.6933 69.7127 15.5828V8.92263C69.7127 6.06255 67.8727 5.10252 65.5326 5.10252C63.2628 5.10252 61.3694 6.0622 61.0752 8.34661C61.0602 8.46255 61.1526 8.56262 61.2695 8.56262H63.2143C63.3159 8.56262 63.4004 8.48613 63.4185 8.38616C63.5866 7.45502 64.2056 6.92257 65.3926 6.92257C66.8127 6.92257 67.2927 7.62259 67.2927 9.02264V9.36265C67.2927 9.47311 67.2031 9.56265 67.0927 9.56265H65.9926C62.9925 9.56265 60.6325 10.4427 60.6325 12.8828C60.6325 15.0628 62.2125 15.9828 64.1326 15.9828ZM64.7126 14.2428C63.5125 14.2428 63.0325 13.6628 63.0325 12.7828C63.0325 11.5027 64.2126 11.0827 66.0526 11.0827H67.0927C67.2031 11.0827 67.2927 11.1722 67.2927 11.2827V12.1227C67.2927 13.4628 66.1926 14.2428 64.7126 14.2428Z"
        fill="#050719"
      />
    </svg>
  )
}
