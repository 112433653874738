import {
  allProfileWithCandidatesExperiencesSkills,
  ProfileWithCandidatesExperiencesSkills,
} from '@/utils/types/common'
import { Tag } from '@prisma/client'
export enum API_ROUTES {
  GET_PROFILES = 'profile',
  GET_FILTERS = 'filters',
  LOGIN = 'auth/login',
  USER = 'user',
  CLIENTS = 'client',
  ROLES = 'role',
  UPDATE_CANDIDATES_STAGE = 'candidate/stage',
  CREATE_CANDIDATE = 'candidate',
  EXPORT = 'profile/csv',
  BULK_ACTIONS = 'candidate/updateMany',
  UPDATE_FEEDBACK = 'feedback',
  OUTREACH_ANALYTICS = 'analytics/outreach',
  SOURCERS_ANALYTICS = 'analytics/sourcer/activity',
  SOURCERS_ACTIVITY = 'analytics/sourcer/',
  GET_TAGS = 'tag',
  UPDATE_CANDIDATE = 'candidate',
  GET_QUALIFICATION_TYPES = 'qualification-type',
  GET_COMPANIES = 'company',
}

export type GetProfiles = {
  profiles: allProfileWithCandidatesExperiencesSkills[]
  offset: number
  count: number
}

export type UpdateCandidatePayload = {
  id: string
  createTags?: { tagId: string; polarity: 'Negative' | 'Positive'; tag: Tag }[]
  removeTags?: string[]
}

export interface CreateRoleDto {
  name: string
  sourcingCriteria?: string
  clientId?: string
  clientName?: string
  roleSpec?: {
    roleAttributes: any
    qualificationRequirements: any[]
  }
}
