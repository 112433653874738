export function Folder(props: any) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M3 7.09265V17.0927C3 18.1972 3.89543 19.0927 5 19.0927H19C20.1046 19.0927 21 18.1972 21 17.0927V9.09265C21 7.98808 20.1046 7.09265 19 7.09265H13L11 5.09265H5C3.89543 5.09265 3 5.98808 3 7.09265Z"
        stroke="#9CA3AF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
