export function Note(props: any) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M4.66667 5.33317H11.3333M4.66667 7.99984H7.33333M8 13.3332L5.33333 10.6665H3.33333C2.59695 10.6665 2 10.0695 2 9.33317V3.99984C2 3.26346 2.59695 2.6665 3.33333 2.6665H12.6667C13.403 2.6665 14 3.26346 14 3.99984V9.33317C14 10.0695 13.403 10.6665 12.6667 10.6665H10.6667L8 13.3332Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
