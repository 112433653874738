const CalanderTwoStrokeIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      aria-hidden="true"
      color="#E5E7EB"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99961 2.47871C3.99961 2.03688 4.35779 1.67871 4.79961 1.67871C5.24144 1.67871 5.59962 2.03688 5.59962 2.47871V3.27871H10.3996V2.47871C10.3996 2.03688 10.7578 1.67871 11.1996 1.67871C11.6415 1.67871 11.9996 2.03688 11.9996 2.47871V3.27871H12.7996C13.6833 3.27871 14.3996 3.99506 14.3996 4.87871V12.8787C14.3996 13.7624 13.6833 14.4787 12.7996 14.4787H3.19961C2.31595 14.4787 1.59961 13.7624 1.59961 12.8787V4.87871C1.59961 3.99506 2.31595 3.27871 3.19961 3.27871H3.99961V2.47871ZM3.99961 6.47871C3.99961 6.03688 4.35779 5.67871 4.79961 5.67871H11.1996C11.6415 5.67871 11.9996 6.03688 11.9996 6.47871C11.9996 6.92054 11.6415 7.27871 11.1996 7.27871H4.79961C4.35779 7.27871 3.99961 6.92054 3.99961 6.47871ZM4.8 8.16309C4.35817 8.16309 4 8.52126 4 8.96309C4 9.40491 4.35817 9.76309 4.8 9.76309H11.2C11.6418 9.76309 12 9.40491 12 8.96309C12 8.52126 11.6418 8.16309 11.2 8.16309H4.8Z"
        fill="currentColor"
      />
    </svg>
  )
}

export { CalanderTwoStrokeIcon }
