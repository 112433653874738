export function SortAsc(props: any) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      color="#1F2937"
      {...props}
    >
      <g id="Sort ascending">
        <path
          id="Icon"
          d="M3 4H16M3 8H12M3 12H9M13 12L17 8M17 8L21 12M17 8V20"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
