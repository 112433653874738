export function ThumbsupActionIcon(props: any) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...props}
    >
      <g id="Thumb down">
        <path
          id="Icon"
          d="M9.99991 10H5.23598C3.74922 10 2.78222 11.5646 3.44712 12.8944L6.94712 19.8944C7.28591 20.572 7.97843 21 8.73598 21H12.7537C12.9172 21 13.0801 20.9799 13.2388 20.9403L16.9999 20M9.99991 10V5C9.99991 3.89543 10.8953 3 11.9999 3H12.0954C12.5949 3 12.9999 3.40497 12.9999 3.90453C12.9999 4.61883 13.2113 5.31715 13.6076 5.91149L16.9999 11V20M9.99991 10H11.9999M16.9999 20H18.9999C20.1045 20 20.9999 19.1046 20.9999 18V12C20.9999 10.8954 20.1045 10 18.9999 10H16.4999"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
