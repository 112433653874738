export const BriefcaseOutlineIcon = (props: any) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Briefcase">
        <path
          id="Icon"
          d="M17.9082 11.0475C15.5921 11.985 13.0605 12.5013 10.4082 12.5013C7.75595 12.5013 5.22428 11.985 2.9082 11.0475M13.7415 5.0013V3.33464C13.7415 2.41416 12.9953 1.66797 12.0749 1.66797H8.74154C7.82106 1.66797 7.07487 2.41416 7.07487 3.33464V5.0013M10.4082 10.0013H10.4165M4.57487 16.668H16.2415C17.162 16.668 17.9082 15.9218 17.9082 15.0013V6.66797C17.9082 5.74749 17.162 5.0013 16.2415 5.0013H4.57487C3.6544 5.0013 2.9082 5.74749 2.9082 6.66797V15.0013C2.9082 15.9218 3.6544 16.668 4.57487 16.668Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
