export function Linkedin(props: any) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      color="#111827"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9463 1.5H3.04952C2.19497 1.5 1.50073 2.17751 1.50073 3.01394V20.9846C1.50073 21.8211 2.19497 22.5 3.04952 22.5H20.9463C21.8023 22.5 22.4994 21.8211 22.4994 20.9846V3.01394C22.5007 2.17751 21.8023 1.5 20.9463 1.5ZM4.61427 9.37238H7.73138V19.3942H4.61427V9.37238ZM6.17422 4.39143C7.17097 4.39143 7.97951 5.19997 7.97951 6.19812C7.97951 7.19487 7.17097 8.00481 6.17422 8.00481C5.17329 8.00481 4.36753 7.19626 4.36753 6.19812C4.36753 5.19997 5.17329 4.39143 6.17422 4.39143ZM12.6699 9.37249H9.68385V19.3943H12.7954V14.4371C12.7954 13.1308 13.0421 11.8637 14.6635 11.8637C16.2624 11.8637 16.2819 13.3581 16.2819 14.5207V19.3958H19.3963V13.8989C19.3963 11.1987 18.8135 9.12436 15.6601 9.12436C14.1449 9.12436 13.1286 9.9552 12.7131 10.7428H12.6699V9.37249Z"
        fill="currentColor"
      />
    </svg>
  )
}
