import { SelectOptions } from '@/components/select'
import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const modules = {
  toolbar: [
    [
      'bold',
      'italic',
      'strike',
      'link',
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
      'code-block',
      'clean',
    ],
  ],
}

export const formats = [
  'bold',
  'italic',
  'strike',
  'link',
  'list',
  'bullet',
  'indent',
  'code-block',
]

// using the length of the data, create options in increments of 10
export const getPaginationIncrements = (length: number) => {
  const options: SelectOptions[] = []
  for (let i = 0; i < length; i += 10) {
    options.push({ id: `${i + 10}`, name: `${i + 10}` })
  }
  return options
}

export function removeEmptyStringValues(obj: any) {
  // Iterate over each key in the object
  for (let key in obj) {
    // Check if the value of the key is an empty string
    if (obj[key] === '') {
      // Delete the key from the object
      delete obj[key]
    }
  }
  return obj
}
