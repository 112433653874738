export function Logo(props: any) {
  return (
    <svg
      width="36"
      height="32"
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      color="#050719"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5931 7.87506C20.5931 8.91062 19.7536 9.75011 18.7181 9.75011C17.6825 9.75011 16.843 8.91062 16.843 7.87506C16.843 6.83949 17.6825 6 18.7181 6C19.7536 6 20.5931 6.83949 20.5931 7.87506ZM23.7384 20.5024C24.7317 19.1367 25.5035 17.3617 25.8984 15.3253C25.9568 15.0242 25.7218 14.7503 25.4151 14.7503H11.9629C11.6562 14.7503 11.4213 15.0242 11.4796 15.3253C11.8745 17.3617 12.6464 19.1367 13.6396 20.5024C15.1082 22.5215 16.9244 23.5 18.689 23.5C20.4536 23.5 22.2699 22.5215 23.7384 20.5024ZM8.85363 14.7503C8.80959 14.4207 8.77411 14.087 8.7475 13.7496C8.68236 12.9238 9.3603 12.2511 10.1887 12.2511L27.1892 12.2503C28.0177 12.2503 28.6957 12.9229 28.6306 13.7489C28.604 14.0866 28.5685 14.4206 28.5244 14.7503C27.6694 21.1505 23.5911 26.0001 18.689 26.0001C13.787 26.0001 9.70869 21.1505 8.85363 14.7503ZM18.7181 21.0001C19.7536 21.0001 20.5931 20.1606 20.5931 19.1251C20.5931 18.0895 19.7536 17.25 18.7181 17.25C17.6825 17.25 16.843 18.0895 16.843 19.1251C16.843 20.1606 17.6825 21.0001 18.7181 21.0001Z"
        fill="currentColor"
      />
    </svg>
  )
}
