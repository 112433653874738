export function AnalyticsLogo(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      aria-hidden="true"
      color="#4F46E5"
      {...props}
    >
      <path
        d="M13.333 6.66634V13.333M9.99967 9.16634V13.333M6.66634 11.6663V13.333M4.99967 16.6663H14.9997C15.9201 16.6663 16.6663 15.9201 16.6663 14.9997V4.99967C16.6663 4.0792 15.9201 3.33301 14.9997 3.33301H4.99967C4.0792 3.33301 3.33301 4.0792 3.33301 4.99967V14.9997C3.33301 15.9201 4.0792 16.6663 4.99967 16.6663Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
