const CalanderIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      aria-hidden="true"
      color="#E5E7EB"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.79961 1.67871C4.35779 1.67871 3.99961 2.03688 3.99961 2.47871V3.27871H3.19961C2.31595 3.27871 1.59961 3.99505 1.59961 4.87871V12.8787C1.59961 13.7624 2.31595 14.4787 3.19961 14.4787H12.7996C13.6833 14.4787 14.3996 13.7624 14.3996 12.8787V4.87871C14.3996 3.99506 13.6833 3.27871 12.7996 3.27871H11.9996V2.47871C11.9996 2.03688 11.6415 1.67871 11.1996 1.67871C10.7578 1.67871 10.3996 2.03688 10.3996 2.47871V3.27871H5.59962V2.47871C5.59962 2.03688 5.24144 1.67871 4.79961 1.67871ZM4.79961 5.67871C4.35779 5.67871 3.99961 6.03688 3.99961 6.47871C3.99961 6.92054 4.35779 7.27871 4.79961 7.27871H11.1996C11.6415 7.27871 11.9996 6.92054 11.9996 6.47871C11.9996 6.03688 11.6415 5.67871 11.1996 5.67871H4.79961Z"
        fill="currentColor"
      />
    </svg>
  )
}

export { CalanderIcon }
