const ClockIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      aria-hidden="true"
      color="#E5E7EB"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18.0791C14.4183 18.0791 18 14.4974 18 10.0791C18 5.66082 14.4183 2.0791 10 2.0791C5.58172 2.0791 2 5.66082 2 10.0791C2 14.4974 5.58172 18.0791 10 18.0791ZM11 6.0791C11 5.52682 10.5523 5.0791 10 5.0791C9.44772 5.0791 9 5.52682 9 6.0791V10.0791C9 10.3443 9.10536 10.5987 9.29289 10.7862L12.1213 13.6146C12.5118 14.0052 13.145 14.0052 13.5355 13.6146C13.9261 13.2241 13.9261 12.5909 13.5355 12.2004L11 9.66489V6.0791Z"
        fill="currentColor"
      />
    </svg>
  )
}

export { ClockIcon }
