export function Flag(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      aria-hidden="true"
      color="#FB7185"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.90039 4.80039C2.90039 3.47491 3.9749 2.40039 5.30039 2.40039H13.3004C13.6034 2.40039 13.8804 2.57159 14.0159 2.84262C14.1514 3.11365 14.1222 3.43798 13.9404 3.68039L11.9004 6.40039L13.9404 9.12039C14.1222 9.3628 14.1514 9.68713 14.0159 9.95816C13.8804 10.2292 13.6034 10.4004 13.3004 10.4004H5.30039C4.85856 10.4004 4.50039 10.7586 4.50039 11.2004V13.6004C4.50039 14.0422 4.14222 14.4004 3.70039 14.4004C3.25856 14.4004 2.90039 14.0422 2.90039 13.6004V4.80039Z"
        fill="currentColor"
      />
    </svg>
  )
}
