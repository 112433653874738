export function DownloadActionIcon(props: any) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      color="#4B5563"
      {...props}
    >
      <g id="Download">
        <path
          id="Icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.6001 20.4004C3.6001 19.7376 4.13736 19.2004 4.8001 19.2004H19.2001C19.8628 19.2004 20.4001 19.7377 20.4001 20.4004C20.4001 21.0631 19.8628 21.6004 19.2001 21.6004H4.8001C4.13736 21.6004 3.6001 21.0631 3.6001 20.4004ZM7.55157 11.1519C8.0202 10.6832 8.78 10.6832 9.24862 11.1519L10.8001 12.7033L10.8001 3.60039C10.8001 2.93765 11.3374 2.40039 12.0001 2.40039C12.6628 2.40039 13.2001 2.93765 13.2001 3.60039L13.2001 12.7033L14.7516 11.1519C15.2202 10.6832 15.98 10.6832 16.4486 11.1519C16.9173 11.6205 16.9173 12.3803 16.4486 12.8489L12.8486 16.4489C12.6236 16.674 12.3184 16.8004 12.0001 16.8004C11.6818 16.8004 11.3766 16.674 11.1516 16.4489L7.55157 12.8489C7.08294 12.3803 7.08294 11.6205 7.55157 11.1519Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
