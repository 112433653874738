import {
  Company,
  FeedbackWithUser,
  ProfileWithCandidatesExperiencesSkills,
  QualificationType,
  RoleWithClient,
  RoleWithClientAndRequirements,
  UserWithPermission,
} from '@/utils/types/common'
import { Bucket, Candidate, FeedbackType, Stage, Tag } from '@prisma/client'
import { Prisma, QualificationFamily } from '@prisma/client'
import axios from 'axios'
import moment from 'moment'
import {
  API_ROUTES,
  CreateRoleDto,
  GetProfiles,
  UpdateCandidatePayload,
} from './types'
import { removeEmptyStringValues } from '@/lib/utils'

export const baseUrlServer = process.env.NEXT_PUBLIC_API || process.env.API
export const baseUrl = '/api/'

export const getProfilesServer = async ({
  offset = 0,
  filters,
  sort,
  search,
  cookie,
}: {
  offset: number
  filters?: any
  sort?: any
  search?: string
  cookie?: string
}) =>
  await getProfiles({
    offset,
    filters,
    sort,
    search,
    cookie,
    url: baseUrlServer as string,
  })

export const getProfilesClient = async ({
  offset = 0,
  filters,
  sort,
  search,
}: {
  offset: number
  filters?: any
  sort?: any
  search?: string
}) =>
  await getProfiles({
    offset,
    filters,
    sort,
    search,
    url: baseUrl,
  })

async function getProfiles({
  offset = 0,
  filters,
  sort,
  search,
  cookie,
  url,
}: {
  offset: number
  filters?: any
  sort?: any
  search?: string
  cookie?: string
  url: string
}): Promise<GetProfiles> {
  const options = () => {
    let string = `include=candidates,experiences,skills&limit=100&offset=${offset}`
    if (search !== '' && search) {
      string += `&search=${search}`
    }
    if (filters) {
      if (filters.decision.length > 0) {
        string += `&decision=${filters.decision}`
      }
      if (filters.stage.length > 0) {
        string += `&stage=${filters.stage}`
      }
      if (filters.roles.length > 0) {
        string += `&roles=${filters.roles}`
      }
      if (filters.sourcer.length > 0) {
        string += `&sourcer=${filters.sourcer}`
      }
      if (filters.location) {
        string += `&location=${filters.location}`
      }
      if (filters.sourcedFrom) {
        string += `&sourcedFrom=${moment(filters.sourcedFrom)
          .startOf('day')
          .toISOString()}`
      }
      if (filters.sourcedTo) {
        string += `&sourcedTo=${moment(filters.sourcedTo)
          .endOf('day')
          .toISOString()}`
      }
      if (filters.exportedFrom) {
        string += `&exportedFrom=${moment(filters.exportedFrom)
          .startOf('day')
          .toISOString()}`
      }
      if (filters.exportedTo) {
        string += `&exportedTo=${moment(filters.exportedTo)
          .endOf('day')
          .toISOString()}`
      }
    }
    if (sort?.sourced) {
      string += `&sourced=${sort.sourced}`
    }
    return string
  }
  const res = await fetch(`${url}${API_ROUTES.GET_PROFILES}?${options()}`, {
    next: { revalidate: 0 },
    credentials: 'include',
    ...(cookie && { headers: { Authorization: `Bearer ${cookie}` } }),
  })

  if (!res.ok) {
    // This will activate the closest `error.js` Error Boundary
    throw new Error('Failed to fetch data')
  }
  const { profiles, count } = await res.json()
  return { profiles, offset, count }
}

export type FeedbackPayload = {
  type: FeedbackType
  quality: Bucket | null | undefined
  comment: string | null | undefined
  candidateId: string
}
export async function postFeedback(
  payload: Partial<FeedbackPayload>
): Promise<FeedbackWithUser> {
  const res = await axios.post(`${baseUrl}feedback`, payload, {
    withCredentials: true,
  })
  return res.data
}

type UpdateProfileParams = {
  linkedinHandle: string
  payload: Prisma.ProfileUpdateInput
}

export async function updateProfile({
  linkedinHandle,
  payload,
}: UpdateProfileParams) {
  const res = await axios.put<ProfileWithCandidatesExperiencesSkills>(
    `${baseUrl}profile/${linkedinHandle}`,
    removeEmptyStringValues(payload),
    {
      withCredentials: true,
    }
  )
  return res.data
}

export type FilterResponse = {
  roleFilters: {
    name: string
    id: string
    isActive: boolean
    client: {
      name: string
      id: string
    }
  }[]
  sourcerFilters: {
    name: string
    id: string
  }[]
  locationFilters: string[]
}

export async function getFilters(): Promise<FilterResponse> {
  const res = await axios.get(`${baseUrl}${API_ROUTES.GET_FILTERS}`, {
    withCredentials: true,
  })
  return res.data
}

export async function getCompanies({
  search,
  ids,
  limit = 10,
  offset = 0,
}: {
  search?: string
  limit?: number
  offset?: number
  ids?: string[]
}) {
  const res = await axios.get<Company[]>(`${baseUrl}${API_ROUTES.GET_COMPANIES}`, {
    params: {
      limit,
      offset,
      search,
      companyIds: ids,
    },
    withCredentials: true,
  })
  return res.data
}

export async function login({ credential }: { credential: string }) {
  const res = await axios.post(
    `${baseUrl}${API_ROUTES.LOGIN}`,
    {
      credential,
    },
    {
      withCredentials: true,
    }
  )
  return res.data
}

export async function getUser() {
  const res = await axios.get<UserWithPermission>(`${baseUrl}${API_ROUTES.USER}`, {
    withCredentials: true,
  })
  return res.data
}

export async function getAllUser() {
  const res = await axios.get<UserWithPermission[]>(
    `${baseUrl}${API_ROUTES.USER}/all`,
    {
      withCredentials: true,
    }
  )
  return res.data
}

export const getClientsWithRolesServer = async ({
  isActive,
  cookie,
}: {
  isActive?: boolean
  cookie?: string
}) => await getClientsWithRoles({ url: baseUrlServer as string, isActive, cookie })

export const getClientsWithRolesClient = async ({
  isActive,
}: {
  isActive?: boolean
}) => await getClientsWithRoles({ url: baseUrl, isActive })

async function getClientsWithRoles({
  url,
  isActive,
  cookie,
}: {
  url: string
  isActive?: boolean
  cookie?: string
}) {
  const res = await fetch(
    `${url}${API_ROUTES.ROLES}?include=client&${
      typeof isActive === 'boolean' ? `isActive=${isActive}` : ''
    }`,
    {
      credentials: 'include',
      ...(cookie && { headers: { Authorization: `Bearer ${cookie}` } }),
    }
  )
  const data = (await res.json()) as RoleWithClient[]

  const TransformedRoles = data.reduce((acc, role) => {
    return [
      ...acc,
      {
        id: role.id,
        name: `${role.client.name} - ${role.name}`,
        isActive: role.isActive,
      },
    ]
  }, [] as { id: string; name: string; isActive: boolean }[])

  return TransformedRoles
}

export async function updateCandidatesStage(ids: string[]) {
  const res = await axios.put(
    `${baseUrl}${API_ROUTES.UPDATE_CANDIDATES_STAGE}`,
    {
      ids,
      stage: Stage.Contacted,
    },
    {
      withCredentials: true,
    }
  )
  return res
}

export async function createCandidate(payload: any) {
  const res = await axios.post(`${baseUrl}${API_ROUTES.CREATE_CANDIDATE}`, payload, {
    withCredentials: true,
  })
  return res.data
}

export type ExportData = {
  'Full Name': string
  'First Name': string
  LinkedIn: string
  'Primary Email': string
  Location: string
  candidate: string
  profileId: string
}

export type ExportProfilesResponse = {
  exportData: ExportData[]
  unenriched: string[]
}

export async function exportProfiles({
  filters,
  payload,
}: {
  filters: any
  payload?: { ids: string[] }
}) {
  const options = () => {
    let string = ``
    if (filters) {
      if (filters.decision?.length > 0) {
        string += `&decision=${filters.decision}`
      }
      if (filters.stage?.length > 0) {
        string += `&stage=${filters.stage}`
      }
      if (filters.roles?.length > 0) {
        string += `&roles=${filters.roles}`
      }
      if (filters.sourcer?.length > 0) {
        string += `&sourcer=${filters.sourcer}`
      }
      if (filters.location) {
        string += `&location=${filters.location}`
      }
      if (filters.sourcedFrom) {
        string += `&sourcedFrom=${moment(filters.sourcedFrom)
          .startOf('day')
          .toISOString()}`
      }
      if (filters.sourcedTo) {
        string += `&sourcedTo=${moment(filters.sourcedTo)
          .endOf('day')
          .toISOString()}`
      }
      if (filters.exportedFrom) {
        string += `&exportedFrom=${moment(filters.exportedFrom)
          .startOf('day')
          .toISOString()}`
      }
      if (filters.exportedTo) {
        string += `&exportedTo=${moment(filters.exportedTo)
          .endOf('day')
          .toISOString()}`
      }
    }
    return string
  }
  const res = await axios.post<ExportProfilesResponse>(
    `${baseUrl}${API_ROUTES.EXPORT}?${options()}`,
    payload,
    {
      withCredentials: true,
    }
  )
  return res.data
}

export type BulkActionPayload = {
  candidateIds?: string[]
  roleId?: string
  decision?: Bucket
  stage?: Stage
  profileIds?: string[]
}

export async function bulkActions(payload: BulkActionPayload) {
  const res = await axios.patch(`${baseUrl}${API_ROUTES.BULK_ACTIONS}`, payload, {
    withCredentials: true,
  })
  return res.data
}

export async function fetchEnrichedEmail(linkedinUrl: string) {
  const response = await axios.get(
    `${baseUrl}profile/enrich?linkedinUrl=${linkedinUrl}`,
    {
      withCredentials: true,
    }
  )
  return response.data
}

export async function updateFeedback(id: string, payload: { comment: string }) {
  const res = await axios.put(
    `${baseUrl}${API_ROUTES.UPDATE_FEEDBACK}/${id}`,
    payload,
    {
      withCredentials: true,
    }
  )
  return res.data
}

export async function getTags(): Promise<Tag[]> {
  const res = await axios.get<Tag[]>(`${baseUrl}${API_ROUTES.GET_TAGS}`, {
    withCredentials: true,
  })
  return res.data
}

export async function updateCandidate(
  updateCandidatePayload: UpdateCandidatePayload
): Promise<Candidate> {
  const { id, ...rest } = updateCandidatePayload
  const res = await axios.patch<Candidate>(
    `${baseUrl}${API_ROUTES.UPDATE_CANDIDATE}/${id}`,
    {
      ...(rest?.createTags &&
        rest?.createTags?.length > 0 && {
          createTags: rest?.createTags?.map((tagData) => {
            const { tag, ...rest } = tagData
            return { ...rest }
          }),
        }),
      ...(rest?.removeTags &&
        rest?.removeTags?.length > 0 && {
          removeTags: rest.removeTags,
        }),
    }
  )
  return res.data
}

export async function getQualificationTypes() {
  const response = await axios.get<QualificationType[]>(
    `${baseUrl}${API_ROUTES.GET_QUALIFICATION_TYPES}`
  )
  const groupedQualificationTypes = response.data.reduce(
    (acc: Record<QualificationFamily, QualificationType[]>, qualificationType) => {
      if (!acc[qualificationType.family]) {
        acc[qualificationType.family] = []
      }
      acc[qualificationType.family].push(qualificationType)
      return acc
    },
    {
      [QualificationFamily.Education]: [],
      [QualificationFamily.Experience]: [],
      [QualificationFamily.TechincalSkills]: [],
      [QualificationFamily.Industry]: [],
    }
  )
  return groupedQualificationTypes
}

export const createRole = async (payload: CreateRoleDto): Promise<any> => {
  const res = await axios.post(`${baseUrl}${API_ROUTES.ROLES}`, payload, {
    withCredentials: true,
  })
  return res.data
}

export const getRolesWithClientAndRequirementsServer = async ({
  isActive,
  cookie,
  include = 'clients',
}: {
  isActive?: boolean
  cookie?: string
  include?: string
}) =>
  await getRolesWithClientAndRequirements({
    url: baseUrlServer as string,
    isActive,
    cookie,
    include,
  })

export const getRolesWithClientAndRequirementsClient = async ({
  isActive,
  include = 'clients',
}: {
  isActive?: boolean
  include?: string
}) => await getRolesWithClientAndRequirements({ url: baseUrl, isActive, include })

export const getRolesWithClientAndRequirements = async ({
  url,
  isActive,
  cookie,
  include,
}: {
  url: string
  isActive?: boolean
  cookie?: string
  include: string
}) => {
  const res = await fetch(
    `${url}${API_ROUTES.ROLES}?include=${include}&${
      typeof isActive === 'boolean' ? `isActive=${isActive}` : ''
    }`,
    {
      credentials: 'include',
      ...(cookie && { headers: { Authorization: `Bearer ${cookie}` } }),
    }
  )
  const data = (await res.json()) as RoleWithClientAndRequirements[]
  return data
}

export const updateRole = async ({
  roleId,
  payload,
}: {
  roleId: string
  payload: CreateRoleDto
}): Promise<any> => {
  const res = await axios.patch(`${baseUrl}${API_ROUTES.ROLES}/${roleId}`, payload, {
    withCredentials: true,
  })
  return res.data
}
